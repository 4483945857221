// @flow
import React from 'react'

import colors from 'superdays-shared/colors'

import DefaultLayout from '../components/DefaultLayout'
import IntroStrip from '../blocks/IntroStrip'
import {StripWithContent, Col} from '../ui/layout'
import FourOFourImage from '../images/icon/icon__404.svg'

const NotFoundPage = () => (
  <DefaultLayout
    title="Yeah, it’s a 404."
    description="You just hit a route that doesn’t exist... the sadness."
  >
    <IntroStrip bottomWave="green" headline="Yeah, it’s a 404.">
      You just hit a route that doesn’t exist... the sadness.
    </IntroStrip>
    <StripWithContent backgroundGradient={colors.greenGradient}>
      <Col paddingVertical={5} align="center">
        <img src={FourOFourImage} />
      </Col>
    </StripWithContent>
  </DefaultLayout>
)

export default NotFoundPage
